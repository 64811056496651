import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Indus_waste extends Component {

    componentDidMount(){
        document.title = "Industrial Waste Water Treatment Plant | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Industrial Waste Water Treatment Plant' subtitle='' BkImg='/assets/images/Babji/water/Banner-Industrial-Waste-Water-Treatment-Plant.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Industrial Waste Water Treatment Plant  </h1>
    <Row >
        <Col lg="6">
        <p>
        Weather proof in nature, these Industrial Waste Water
Treatment Plant are reliable in nature and show
optimal working up to many years. These treatment
solutions are easy to install and reliable in nature. These
solutions are quality and safe to use. We apply
minimum charges for these waste treatment solutions.
</p> 

 
   
                              </Col>
       <Col lg="6" >
       <Image src='/assets/images/Babji/water/Banner-Industrial-Waste-Water-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
        </Col>

    </Row>

  
</Container>
<hr></hr>
     
        <Footer/>
        </div>
    );
} 
}
export default Indus_waste;