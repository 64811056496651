import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Mbbr extends Component {

    componentDidMount(){
        document.title = "MBBR Fab Media | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='MBBR Fab Media' subtitle='' BkImg='/assets/images/Babji/MBBR-Fab.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">MBBR Fab Media (Moving Bio Bed Reactors)</h1>
    <Row >
        <Col lg="8">
        <p>
        MBBR is a biofilm process. This technology is essentially the same as the Activated
Sludge Process (ASP) except that the media suspended in the reactor offers
additional surfaces for the microbes to grow and this in turn maximizes the
growth of microbes in a given volume of aeration tank compared to the
conventional aeration without the media and to that extent, it does appear
preferable. MBBR system is often installed as a retrofit of existing activated sludge
tanks to increase the capacity of the existing system. Diffused aeration is also
required. FAB technology is akin to MBBR except that instead of the media in
suspension, the media is kept stationary and fluidized in the aeration tank.


            </p> 
         
            <Image src='/assets/images/Babji/products/MBBR-Fab.png' alt={config.SITE_NAME} fluid /> 

                              </Col>
       <Col lg="4" >
            <h3 className='secondHeading text-center mb-3'>Advantages</h3>

            <ul>
              <li>Require less space than ASP.</li>
  <li>Lower Sludge Production.</li>
 <li>Respond to load fluctuations without operator intervention</li>
 <li>There are no limitations of height as long as compressors can be suitably used.</li>
 <li>Circular structures can be used to economize on.</li>
 <li>construction costs & time.</li>
 <li>The structures can be easily covered for indoor.</li>
 <li>air quality when needed.</li>
 <li>Requires lower footprints compared to</li>
 <li>conventional activated sludge.</li>
 <li>Easy to operate and maintain</li>
                 
            </ul>
                              </Col>

    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
    <Col lg='12'> 
        <div class='table-responsive'>
        <Table     bordered hover>
            <thead>
                <tr>
                    <th colSpan={2}>(MBBR MEDIA, BIO PAC MEDIA, (FAB MEDIA)</th>
                     
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Types of media</td>
                    <td>MBBR, BIO, PAC, (FAB MEDIA)</td>
                
                </tr>
                <tr>
                    <td>MOC of Media</td>
                    <td>POLYPROPYLENE (PP)</td>
                </tr>
                <tr>
                    <td>Specific Gravity/Dencity 0.93</td>
                    <td>0.93</td>
                </tr>
                <tr>
                    <td>Shape</td>
                    <td>CYLINDRICAL</td>
                </tr>
                <tr>
                    <td>Height</td>
                    <td>12MM</td>
                </tr>
                <tr>
                    <td>Diametrer</td>
                    <td>22MM</td>
                </tr>
                <tr>
                    <td>Surface Area (M2)</td>
                    <td>400-425 (M2)</td>
                </tr>
                <tr>
                    <td>Colour</td>
                    <td>BLACK</td>
                </tr>
                <tr>
                    <td>thickness</td>
                    <td>1.0MM</td>
                   
                </tr>
                <tr>
                    <td>Max. Continuous
working temp
</td>
                    <td>55C</td>
                 
                </tr>

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default Mbbr;