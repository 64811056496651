import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubHeader from '../layout/subheader';
import { Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
   
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const Contactform = () => {
 
       
     

        const [mailerState, setMailerState] = useState({
          name: "",
          phone:"",
          email: "",
          message: "",
        });
    
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
    
        const submitEmail = async (e) => {
           e.preventDefault();
          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"send/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");
                toast.success("Message Sent!" , {autoClose: 2000})

              } else if (resData.status === "fail") {
                //alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                email: "",
                name: "",
                phone:"",
                message: "",
              });
 
            });
            
        };
    
         return(
          <>
            <SubHeader  Title='Contact' subtitle='' BkImg='assets/images/Babji/Contact-Us-Banner.jpg'/> 

             <Container className="my-3">
             <ToastContainer />

                <Row>
                    <Col lg="6">
                        <h3  className="py-3">Contact Us</h3>
                        <Form  onSubmit={submitEmail}
>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="name " onChange={handleStateChange}
 name="name"  value={mailerState.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={handleStateChange}
           name="message"     value={mailerState.message}/>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" className='btn btn-primary'  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                    </Col>
                    <Col  lg="6" className="contactUS">
                    <h3  className="py-3">Inquire Now</h3>



<h4 className="pt-3"> <Image src='assets/images/Babji/Map.png' alt={config.SITE_NAME} fluid />
 Reg. Office Address</h4>
 <hr></hr>

<h5>India </h5>
<p><b>Pune : </b>Super Mall S-20, Salunke Vihar RD., Wanowri - Pune 411040</p>

<p><b>Contact : </b> <a href="tel:+91 8975785582">+91 8975785582</a> | <a href="tel:+91 9028046401"> +91 9028046401 </a></p>
<p><b> Email:</b> <a href="mailto:sales@babjifiltrations.com">sales@babjifiltrations.com </a> |  
<a href="mailto:info@babjifiltrations.com"> info@babjifiltrations.com </a>  </p>

<div className='borderTop'></div>

<p><b>Mumbai : </b> D3 Godwan no 8 Prerna Complex Opp Pritesh
Complex Near Ramnath Hotel Bhiwandi - 421302
</p>

<p><b>Contact : </b> <a href="tel:+91 8975785582">91 8975785582 </a> | <a href="tel:+91 9028046401"> +91 9028046401 </a></p>
<p><b> Email :</b> <a href="mailto:salesmumbai@babjifiltrations.com">salesmumbai@babjifiltrations.com </a> |  
<a href="mailto:info@babjifiltrations.com"> info@babjifiltrations.com </a>  </p>
<div className='borderTop'></div>
<p><b>Bangalore : </b>P 40 1st floor sector 26 Gottigere Bangalore 560083</p>


<p><b>Contact : </b> <a href="tel:+91 8975785582">91 8975785582 </a> | <a href="tel:+91 9028046401"> +91 9028046401 </a></p>
<p><b> Email :</b> <a href="mailto: sales@babjifiltrations.com">sales@babjifiltrations.com </a> |  
<a href="mailto: info@babjifiltrations.com"> info@babjifiltrations.com </a>  </p>

<hr></hr>
<h5>DUBAI </h5>
<p><b>DUBAI : </b>Hatemi Apartment 1st floor 114 Al Bustan, Liwara 1 Ajman UAE</p>

<p><b>Contact : </b> <a href="tel:+971544867153">+971 54 486 7153 </a> | <a href="tel:+9171582104351"> +9171 582104351 </a></p>
<p><b> Email :</b> <a href="mailto:gulf@babjifiltrations.com">gulf@babjifiltrations.com </a> |  
<a href="mailto:exports@babjifiltrations.com"> exports@babjifiltrations.com </a>  </p>
<hr></hr>
<h5>BANGLADESH </h5>
<p><b>Dhaka : </b>121/3 Nawabpur Road,
Dhaka 1100, Bangladesh
</p>

<p><b>Contact : </b> <a href="tel:+880 1919-324334">+880 1919-324334 </a>  </p>
<p><b> Email :</b> <a href="mailto:exports@babjifiltrations.com">exports@babjifiltrations.com </a>  
   </p>
 
   <hr></hr>

   
      <h4>Email </h4>
<p><i className="fa fa-envelope"></i> <a href="mailto:sales@babjifiltrations.com">sales@babjifiltrations.com </a> | <a href="mailto:info@babjifiltrations.com
">info@babjifiltrations.com
 </a></p>

 

   {/*    <h4>Phone Office</h4> 
   <a href={"mailto:Sales@babjifiltrations.com" + config.email_id}>{config.email_id} </a> 
<p><i className="fa fa-mobile"></i> <a href= {"tel:" + config.contact1}  >{config.contact1}</a> | <a href= {"tel:" + config.contact2}> {config.contact2}</a> | <a href= {"tel:" + config.contact3}> {config.contact3}</a> | <a href= {"tel:" + config.contact4}> {config.contact4}</a> | <a href= {"tel:" + config.contact5}> {config.contact5}</a></p> */}
                     </Col>
                </Row>
            </Container>
            </>

            
         )
    }


export default Contactform;