import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';

class AboutHome extends Component {
        render() {

    return(
        <>
        <div className='aboutSection' style={{backgroundImage:`url(/assets/images/Babji/Babji-patch.png)`,}}>
            <Container className="py-5 ">
                <Row className=' d-md-block d-lg-none'>
                    <Col lg='12' className="aboutText"> <h2>About Us</h2> </Col>
                </Row>
                 
                <Row className="pt-5"> 
                    <Col sm="5" lg="5"> 
                    
                     <Image src='/assets/images/Babji/About-us-image.png' alt={config.SITE_NAME} fluid /> 
                     
                     </Col>
                    <Col  sm="7" lg="6" className="py-5" >  
           <p>Company Babji Filtrations Pvt. Ltd. based in India Pune and Mumbai Maharashtra. It is a part of Raj Carbon and Chemicals. Babji Filtrations Pvt. Ltd. Has branches all over India and has now evolved with export also like countries Dubai, Bangladesh, Tanzania and many more countries coming soon. Where the activated carbon and filter media requirements are in very good quantity. The group of companies is collectively manufacturing Filtration Media & Water treatment plants unit.</p>
           {/* Raj carbon and chemicals is manufacturer company of Activated Carbon Granular Coconut and Wood base Coal, Activated Carbon Powder, Activated Carbon Pellets, Water Filter Media Quartz Silica Sand, Filter Media Pebbles Gravels, MNO2 Iron Removal Media (green sand media), Anthracite Filter Media, MBBR Fab Media (moving bio bed reactors), Garnet Sand, Silica Sand, Silica Powder. */}
 
        <Row className='productsNameDiv'>

            <Col lg='6'>
            <p><b>Filtration Media</b></p>
            <ul>

            <li> <NavLink to="/activated-carbon-granular"   activeclassname="active">Activated Carbon Granular</NavLink></li>
              <li> <NavLink to="/activated-carbon-powder"   activeclassname="active">Activated Carbon Powder</NavLink></li>
              <li> <NavLink to="/anthracite-filter-media"   activeclassname="active">Anthracite Filter Media</NavLink></li>
              <li> <NavLink to="/pallets-activated-carbon"   activeclassname="active">Pellets Activated Carbon </NavLink></li>
              <li> <NavLink to="/mno2-iron-removal-filter-media"   activeclassname="active">MNO2 Iron Removal Filter Media </NavLink></li>
              <li> <NavLink to="/quartz-silica-sand"   activeclassname="active">Filter Quartz Silica Sand </NavLink></li>
              <li> <NavLink to="/filter-pebbles-gravels-media"   activeclassname="active">Filter Pebbles Gravels Media</NavLink></li>
              <li> <NavLink to="/mbbr-fab-media"   activeclassname="active">MBBR Fab Media </NavLink></li>
              <li> <NavLink to="/garnet-sand"   activeclassname="active">Garnet Sand</NavLink></li>
            </ul>
            </Col>
            <Col lg='6'>
            <p><b>Water Treatment Plants Unit</b></p>
            <ul>
            <li> <NavLink to="/sewage-water-treatment-plant"   activeclassname="active">Sewage Water Treatment Plant</NavLink></li>
              <li> <NavLink to="/waste-water-treatment-plant"   activeclassname="active">Waste Water Treatment Plant</NavLink></li>
              <li> <NavLink to="/industrial-waste-water-treatment-plant"   activeclassname="active">Industrial Waste Water Treatment Plant</NavLink></li>
              <li> <NavLink to="/packaged-sewage-treatment-plant"   activeclassname="active">Packaged Sewage Treatment Plant </NavLink></li>
              <li> <NavLink to="/reverse-osmosis-plant"   activeclassname="active">Reverse Osmosis Plant </NavLink></li>
            </ul>
            </Col>
        </Row>
                       </Col>

                       <Col sm="1" lg="1" className='d-none d-lg-block' >
                       <div class="art-side-form-side-text">About Us</div>
                        
                       </Col>
                 </Row>
            </Container>
        </div>


        <div className='py-5'>

            <Container className='pt-5 productSection'>

            <Row className=' d-md-block d-lg-none text-center mb-4  '>
                    <Col> <h2>Our Products</h2> </Col>
                </Row>


                <Row>
                    <Col lg="1">
                    <div class="art-side-form-side-text2 d-none d-lg-block">Our Products</div>
                    </Col>
                    <Col lg='11'>
                    <Tabs
      defaultActiveKey="home"
      id="fill-tab-example"
       fill
    >
      <Tab eventKey="home" title="Filtration Media" >
      
                        <Row>
                            <Col lg="4" sm='6'>
                            <NavLink to="/activated-carbon-granular"   activeclassname="active">
                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/Granular.png' alt={config.SITE_NAME} fluid /> 

                                <p>Activated Carbon Granular</p>

                                </div>
                                </NavLink>
                                    </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/activated-carbon-powder"   activeclassname="active">
                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/Powder-Activated-Carbon.png' alt={config.SITE_NAME} fluid /> 
                                <p>Activated Carbon Powder</p>
                                </div>
                                </NavLink>

                             </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/pallets-activated-carbon"   activeclassname="active">
                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/Pellets.png' alt={config.SITE_NAME} fluid /> 
                                <p>Pellets Activated Carbon</p>

                                </div>
                                </NavLink>

                                </Col>
                         
                            <Col lg="4" sm='6'>
                            <NavLink to="/quartz-silica-sand"   activeclassname="active">
                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/Filter-Qurtz.png' alt={config.SITE_NAME} fluid /> 
                                <p>Filter Quartz Silica Sand</p>
                                </div>
                                </NavLink>

                                    </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/filter-pebbles-gravels-media"   activeclassname="active">

                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/Filter-Pebbles.png' alt={config.SITE_NAME} fluid /> 
                                <p>Filter Pebbeles and Gravels</p>

                                </div>
                                </NavLink>

                             </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/mno2-iron-removal-filter-media"   activeclassname="active">
                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/MNO2-Iron.png' alt={config.SITE_NAME} fluid />
                                <p>MNO2 Iron Removal Media</p>
 
                                </div>
                                </NavLink>

                                </Col>
                        </Row>
                        <Row>

                        <Col lg={ {span:'4' , offset:'2' }}  >
                            <NavLink to="/anthracite-filter-media"   activeclassname="active">

                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/anthrasite.png' alt={config.SITE_NAME} fluid /> 
                                <p>Anthracite Filter Media</p>

                                </div>
                                </NavLink>

                                    </Col>
                            <Col lg={ {span:'4'  }}  >
                            <NavLink to="/mbbr-fab-media"   activeclassname="active">

                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/box/MBBR-Fab.png' alt={config.SITE_NAME} fluid /> 
                                <p>MBBR Fab Media</p>

                                </div>
                                </NavLink>

                                    </Col>
                                    </Row>

                                    </Tab>
      <Tab eventKey="profile" title="Water Treatment Plants Unit"  className='waterImages'>

      <Row>
                            <Col lg="4" sm='6'>
                            <NavLink to="/sewage-water-treatment-plant"   activeclassname="active">
                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/water/Sewage-Water-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 

                                <p>Sewage Water Treatment Plant</p>

                                </div>
                                </NavLink>
                                    </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/waste-water-treatment-plant"   activeclassname="active">
                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/water/Waste-Water-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
                                <p>Waste Water Treatment Plant</p>
                                </div>
                                </NavLink>

                             </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/industrial-waste-water-treatment-plant"   activeclassname="active">
                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/water/Industrial-Waste-Water-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
                                <p>Industrial Waste Water Treatment Plant</p>

                                </div>
                                </NavLink>

                                </Col>
                         
                            <Col lg="4" sm='6'>
                            <NavLink to="/packaged-sewage-treatment-plant"   activeclassname="active">
                                <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/water/Packaged-Sewage-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
                                <p>Packaged Sewage Treatment Plant</p>
                                </div>
                                </NavLink>

                                    </Col>
                            <Col lg="4" sm='6'> 
                            <NavLink to="/reverse-osmosis-plant"   activeclassname="active">

                            <div className='BabjiproductDiv'>
                                <Image src='/assets/images/Babji/water/REVERSE-OSMOSIS-PLANT.jpg' alt={config.SITE_NAME} fluid /> 
                                <p>Reverse Osmosis Plant</p>

                                </div>
                                </NavLink>

                             </Col>
                        
                        </Row>
       </Tab>
      
    </Tabs>

                    </Col>
                 </Row>
            </Container>


        </div>

<div className='shipSection ' >
<Container className='py-5'>
<h1 className=" pb-3  mt-0">STP Project MBBR System</h1>

                <Row>
                    <Col>
                    <div style={{ width: '100%', height: 'auto' }}>
      <Ratio aspectRatio="16x9">
      <video width="100%" height="440" controls>
  <source src="/assets/images/Babji/babji.mp4" type="video/mp4"/>
  <source src="/assets/images/Babji/babji.mp4" type="video/ogg"/>
 </video>
      </Ratio>
    </div>
                    </Col>
                </Row>
            </Container>
</div>
        <div className='shipSection d-none'>
            <Container className='py-5'>
                <Row>
                    <Col lg='3'>                             
                       <Image src='/assets/images/Babji/Client.png' alt={config.SITE_NAME} fluid />
                       <h4>1425 <p>Clients</p></h4> 
</Col>
                    <Col lg='3'> <Image src='/assets/images/Babji/Employees.png' alt={config.SITE_NAME} fluid />
                       <h4>30 <p>Employees</p></h4></Col>
                    <Col lg='3'> <Image src='/assets/images/Babji/Output-per-Year.png' alt={config.SITE_NAME} fluid />
                       <h4>1000 <p>Output per year</p></h4></Col>
                    <Col lg='3'> <Image src='/assets/images/Babji/Delivery-per-year.png' alt={config.SITE_NAME} fluid />
                       <h4>900 <p>Shipment per year</p></h4></Col>
                </Row>
            </Container>
        </div>
        <div className='downloadSection  py-5 text-center'  style={{backgroundImage:`url(/assets/images/Babji/Download-Bar.png)`,}}>

             
            <a download="BABJI_FILTRATIONS_PVT_LTD.pdf" target="_blank" href="/assets/images/Babji/BABJI_FILTRATIONS_PVT_LTD.pdf">
        <h3 className=' text-center'>Download our product brochure <Image src='/assets/images/Babji/PDF-Icon.png'/></h3>
        </a>
        </div>
        </>

    );
}
    }
export default AboutHome;