import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/404';
import About from './pages/about';
import Contact from './pages/contact';
import Speciality from './pages/speciality';
import Attraction from './pages/attraction';
import ProductsPage from './components/section/about_us';
import ProductsMain from './pages/productsMain';
import PalletsActivtaed from './pages/pellets_activated';
import Mno2 from './pages/mno2';
import Quartz from './pages/quartz';
import FilterPebbles from './pages/filter_pebbels';
import Mbbr from './pages/mbbr';
import Garnet_sand from './pages/garnet_sand';
import ActivatedCarbon from './pages/activated_carbon_granular';
import ActivatedCarbonPowder from './pages/Activated_carbon_powder';
import Anthracite from './pages/anthracite';
import ScrollToTop from './ScrollToTop';
import Sewage_Water from './pages/sewage_water';
import Waste_Water from './pages/waste_water';
import Indus_waste from './pages/industrial_waste';
import Packaged_sewage from './pages/packaged_sewage';
import Reverse_osmosis from './pages/reverse_osmosis';

function App() {
  return (
   <BrowserRouter>
      <ScrollToTop />

    <Routes>
      <Route path="/" element= {<Home />} />
      <Route path="/home" element= {<Home />} />
      <Route path="/contact" element= {<Contact />} />
      <Route path="/about" element= {<About/>} />
      <Route path="/products" element= {<ProductsMain/>} />
      <Route path="/pallets-activated-carbon" element= {<PalletsActivtaed/>} />
      <Route path="/mno2-iron-removal-filter-media" element= {<Mno2/>} />
      <Route path="/quartz-silica-sand" element= {<Quartz/>} />
      <Route path="/filter-pebbles-gravels-media" element= {<FilterPebbles/>} />
      <Route path="/mbbr-fab-media" element= {<Mbbr/>} />
      <Route path="/garnet-sand" element= {<Garnet_sand/>} />
      <Route path="/activated-carbon-granular" element= {<ActivatedCarbon/>} />
      <Route path="/activated-carbon-powder" element= {<ActivatedCarbonPowder/>} />
      <Route path="/anthracite-filter-media" element= {<Anthracite/>} />
      <Route path="/sewage-water-treatment-plant" element= {<Sewage_Water/>} />
      <Route path="/waste-water-treatment-plant" element= {<Waste_Water/>} />
      <Route path="/industrial-waste-water-treatment-plant" element= {<Indus_waste/>} />
      <Route path="/packaged-sewage-treatment-plant" element= {<Packaged_sewage/>} />
      <Route path="/reverse-osmosis-plant" element= {<Reverse_osmosis/>} />
        <Route path="*" element={<ErrorPage />} />

    </Routes>

   </BrowserRouter>
  );
}

export default App;
