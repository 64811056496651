import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Anthracite extends Component {

    componentDidMount(){
        document.title = "Anthracite Filter Media | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Anthracite Filter Media' subtitle='' BkImg='/assets/images/Babji/Anthraciti-filter-media.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Anthracite Filter Media</h1>
    <Row >
        <Col lg="6">
        <p>
        Our range of Anthracite Coal offer best fit solutions for application in
commercial, industrial, chemical and manufacturing industries.
Categorized as the highest quality grade dark black coal, we supply
Anthracite Coal that is from 40 to 90& of carbon content and has very
low percentage of moisture and volatile matter. Owing to its clean
burning properties and high-temerature potential, it makes for an
exceptional source of energy.


</p>
<Image src='/assets/images/Babji/Anthraciti-filter-media.jpg' alt={config.SITE_NAME} fluid /> 

                         </Col>
       <Col lg="3" >
            <h3 className='secondHeading text-center mb-3'>Applications</h3>
             <li> High suitable for residentials and commercial heating. </li>
<li>  Waste treatment and water filtration.</li>
 
                               </Col>
                               <Col lg="3" >
                               <h3 className='secondHeading text-center mb-3'>Features</h3>
             <li> 40-90% Carbon Content </li>
             <li> Low dust production  </li>
             <li> Clean burning property </li>
             <li>High temperature potential </li>

</Col>
                              
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
        <Col lg='12'>
        <div class='table-responsive'>
        <Table     bordered hover>
            <thead>
                <tr>
                    <th> </th>
                    <th></th>
                    <th colSpan={4}>Anthracite </th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Properties</td>
                    <td></td>
                    <td>40%</td>
                    <td>70%</td>
                    <td>80%</td>
                    <td>90%</td>
                     
                </tr>
                <tr>
                    <td>Appearance</td>
                    <td></td>
                    <td colSpan={4}>Black Granules</td>
                     
                    
                </tr>
                <tr>
                    <td>Moisture</td>
                    <td>%max </td>
                    <td>10</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                 </tr>
                 <tr>
                    <td>Carbon Content</td>
                    <td>%min </td>
                    <td>40</td>
                    <td>70</td>
                    <td>80</td>
                    <td>90</td>
                 </tr>

                 <tr>
                    <td>Ash</td>
                    <td>%max </td>
                    <td>40</td>
                    <td>25</td>
                    <td>15</td>
                    <td>5</td>
                 </tr>
                <tr>
                    <td>Hardness (MOH) </td>
                    <td></td>
                    <td>2-3</td>
                    <td>2-3</td>
                    <td>2-3</td>
                    <td>2-3</td>
                 </tr>
                

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default Anthracite;