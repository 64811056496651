import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class ActivatedCarbonPowder extends Component {

    componentDidMount(){
        document.title = "Activated Carbon Powder | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Activated Carbon Powder' subtitle='' BkImg='/assets/images/Babji/Powder-Activated-Carbon.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Activated Carbon Powder</h1>
    <Row >
        <Col lg="8">
        <p>
        Our products Activated Charcoal Powder are high in demand due to their
premium quality and affordable prices. </p> 
<p>Powdered Activated Carbon (PAC) is a high-performance absorbent
engineered to remove harmful contaminants in the air, gas, and liquid phases.
The potable water industry has long used PAC products to effectively remove
taste and odor compounds, and provide clean water that is safe to drink.
</p>


<Image src='/assets/images/Babji/products/Powder-Activated-Carbon.png' alt={config.SITE_NAME} fluid /> 

                         </Col>
       <Col lg="4" >
            <h3 className='secondHeading text-center mb-3'>Applications</h3>
             <li>Water and Wastewater Treatment Industrial</li>
<li>Air Purification and Gas Purification</li>
<li>Medical And Pharmaceutical Industries</li>
<li>Chemical Purification</li>
<li>Oil and Gas Purification and many more applications.</li>

                               </Col>

                              
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-4'>

    <Col lg="4" >
            <h3 className='secondHeading text-center mb-3'>Unwashed Activated Carbon</h3>
            <p>produced from Pine Wood,
produced through steam
activation, which has very little
impurities resulting to higher
adsorption
</p>

                               </Col>
                               <Col lg="8">
           <div class='table-responsive'>

        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    <th>P-100</th>
                    <th>P-150</th>
                    <th>P-200</th>
                    <th>P-250</th>
                    <th>P-280</th>
                    <th>P-320</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MB Value</td>
                    <td>mg/g</td>
                    <td>100</td>
                    <td>150</td>
                    <td>200</td>
                    <td>250</td>
                    <td>280</td>
                    <td>320</td>
                </tr>
                <tr>
                    <td>KMnO4</td>
                    <td>%min</td>
                    <td>20</td>
                    <td>25</td>
                    <td>45</td>
                    <td>55</td>
                    <td>60</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>Ash</td>
                    <td>%max</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Iron (ppm)</td>
                    <td>max</td>
                    <td>1000</td>
                    <td>1000</td>
                    <td>800</td>
                    <td>800</td>
                    <td>600</td>
                    <td>500</td>
                </tr>
                <tr>
                    <td>Acid Soluble (HCI) </td>
                    <td>%max</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>Water Soluble  </td>
                    <td>%max</td>
                    <td>5</td>
                    <td>5</td>
                    <td>3</td>
                    <td>2</td>
                    <td>2</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>LO.D </td>
                    <td>%max</td>
                    <td>20</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>10</td>
                </tr>
                <tr>
                    <td>CI & SO4 </td>
                    <td colSpan={7}  className="blackBg">USP</td>
                </tr>
                <tr>
                    <td>pH</td>
                    <td colSpan={7} className="blackBg">Alkaline</td>
                </tr>
                <tr>
                    <td>Particle Size </td>
                    <td colSpan={7} className="blackBg">98% Passes through 100 Mesh</td>
                </tr>
                 

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>

    <hr></hr>
{/* ============================================================================================ */}
    <Row className='py-4'>

    <Col lg={{span:4 , order:2}}>
            <h3 className='secondHeading text-center mb-3'>Acid Washed
Activated Carbon
</h3>
            <p>Acid Washed Activated Carbon
from Pine, wood, chemically
activated. Suitable for several
application among which are
dechlorination, decolorisation and
deodourisation.

</p>

     </Col>
     <Col lg={{span:8 , order:1}}>
        <div class='table-responsive'>
     <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                     <th>W-150</th>
                    <th>W-200</th>
                    <th>W-250</th>
                    <th>W-280</th>
                    <th>W-320</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MB Value</td>
                    <td>mg/g</td>
                     <td>150</td>
                    <td>200</td>
                    <td>250</td>
                    <td>280</td>
                    <td>320</td>
                </tr>
                <tr>
                    <td>KMnO4</td>
                    <td>%min</td>
                     <td>25</td>
                    <td>45</td>
                    <td>55</td>
                    <td>60</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>Ash</td>
                    <td>%max</td>
                     <td>8</td>
                    <td>5</td>
                    <td>3</td>
                    <td>2</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Iron (ppm)</td>
                    <td>max</td>
                     <td>300</td>
                    <td>200</td>
                    <td>200</td>
                    <td>150</td>
                    <td>100</td>
                </tr>
                <tr>
                    <td>Acid Soluble (HCI) </td>
                    <td>%max</td>
                     <td>5</td>
                    <td>3</td>
                    <td>2</td>
                    <td>2</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>Water Soluble  </td>
                    <td>%max</td>
                    <td>5</td>
                     <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>LO.D </td>
                    <td>%max</td>
                    <td>20</td>
                     <td>10</td>
                    <td>10</td>
                    <td>10</td>
                    <td>8</td>
                </tr>
                <tr>
                    <td>CI & SO4 </td>
                    <td colSpan={7}  className="blackBg">USP</td>
                </tr>
                <tr>
                    <td>pH</td>
                    <td colSpan={7} className="blackBg">6.0 TO 7.5 </td>
                </tr>
                <tr>
                    <td>Particle Size </td>
                    <td colSpan={7} className="blackBg">98% Passes through 100 Mesh</td>
                </tr>
                 

            </tbody>
        </Table>
        </div>
     </Col>

    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default ActivatedCarbonPowder;