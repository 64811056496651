import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class PalletsActivtaed extends Component {

    componentDidMount(){
        document.title = "Pellets Activated Carbon | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Pellets Activated Carbon' subtitle='' BkImg='/assets/images/Babji/Pellets-Activated-Carbon.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Pellets Activated Carbon</h1>
    <Row >
        <Col lg="12">
        <p>
        Pelletized forms are activated Carbon compressed into formed cylinders and
have wide variety of users removing contaminants such as volatile organic
compounds (VOC's) and mercury from natural gas as well as controlling odor.
Pelletized Activated Carbon is created by Extruding Activated Carbon into
cylindrical shaped pellets with diameter 3, 4,5 & 8mm. Their high activity and
surface area make it ideal for many vapor phase applications where
low-pressure drop is a consideration

            </p> 
            <p>Temperature steam activation an manufactured with suitable binders
under stringent quality control. The Pelletized carbon has a low ash content,
large surface area, high Mechanical srength, extended pore volume, and
chemical stability. These characteristics make it ideal for removing a
variety of contaminants from air and gas streams, the recovery of solvents
and for evaporative emissions controls.
</p>
<p>By varying manufacturing conditions, internal pore
structures created that impart unique adsorption
properties specific to each product type. It is widely used
for desulfurization and de-nitrificatiopn of smokes and gas
In the thermal power plant, steel plant, nonferrous metals
smelting, fuel, gas, coal, furnace ammonia, garbage,
combustion industries etc.
</p>
        
<Image src='/assets/images/Babji/products/Pellets.png' alt={config.SITE_NAME} fluid /> 

                              </Col>
        
 
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
        <Col lg='12'>
    <div class='table-responsive'>

        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    <th>RCC-500</th>
                    <th>RCC-600</th>
                    <th>RCC-700</th>
                    <th>RCC-800</th>
                    <th>RCC-900</th>
                    <th>RCC-1000</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Diameter</td>
                    <td>mm</td>
                    <td colSpan={6} className='blackBg'> 3,4,5 & 8mm </td>
                </tr>
                <tr>
                    <td>lodine Adsorption</td>
                    <td>min. mg/gm </td>
                    <td>500</td>
                    <td>600</td>
                    <td>700</td>
                    <td>800</td>
                    <td>900</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Ash Content</td>
                    <td>%max  </td>
                    <td>8-10</td>
                    <td>8-10</td>
                    <td>8-10</td>
                    <td>8-10</td>
                    <td>8-10</td>
                    <td>8-10</td>
                </tr>
                <tr>
                    <td>Moisture</td>
                    <td>%max  </td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Bulk Density</td>
                    <td>gm./cc  </td>
                    <td>0.5-0.6</td>
                    <td>0.5-0.6</td>
                    <td>0.5-0.6</td>
                    <td>0.5-0.6</td>
                    <td>0.5-0.6</td>
                    <td>0.5-0.6</td>
                </tr>
                <tr>
                    <td>Hardness</td>
                    <td>min  </td>
                    <td>95</td>
                    <td>95</td>
                    <td>95</td>
                    <td>95</td>
                    <td>95</td>
                    <td>95</td>
                </tr>
                <tr>
                    <td>CTC Adsorption</td>
                    <td>min  </td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>35-40</td>
                    <td>40-45</td>
                    <td>50-55</td>
                </tr>
                 

            </tbody>
        </Table>
         </div>
        <p><b>Specification of extruded Activated carbon (Pellets)</b></p>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default PalletsActivtaed;