import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class ActivatedCarbon extends Component {

    componentDidMount(){
        document.title = "Activated Carbon Granular | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Activated Carbon Granular' subtitle='' BkImg='/assets/images/Babji/Granula-Activate-Carbon.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Activated Carbon Granular</h1>
    <Row >
        <Col lg="6">
        <p>
        Activated carbon is Manufactured In Coconut and Coal Base with Steam
Activation and Chemicals Activation Process. Material: Coconut Shell, Coal Shell,
consisting of micro mesh and macro pore structures. This unique pore distribution
makes Coconut and Coal based activated carbon ideal to remove small to large
molecular structures. Typical applications include but not limited to odor control
in liquid & vapor phase applications, improve taste in liquids, liquid decolonization
and purification of wastewater & potable water.


            </p> 
            <Image src='/assets/images/Babji/Granula-Activate-Carbon.jpg' alt={config.SITE_NAME} fluid /> 

                         </Col>
       <Col lg="3" >
            <h3 className='secondHeading text-center mb-3'>Applications</h3>
            <li> Water and Wastewater Treatment Industrial  </li>
<li>Portable Water Purification </li>
<li>Air Purification and Gas Purification</li>
<li>Energy Storage</li>
<li>Purification Of Food And Beverage</li>
<li>Medical And Pharmaceutical Industries</li>
<li>Chemical Purification </li>
<li>Oil and Gas Purification & Many more applications
Features
</li>
                               </Col>

                             <Col lg="3">
                             <h3 className='secondHeading text-center  mb-3'>Features</h3>
                             <ul>
                                <li> Low dust production</li>
                                <li> Good abrasion resistance</li>
                                <li>High organic compound capacity</li>
                                <li>  Enhanced pore structure</li>
                             </ul>
                             <ul>
                
            </ul>
                              </Col>
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>

        
        <Col   lg="12">
        <div class='table-responsive'>

        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    <th>G-500</th>
                    <th>G-600</th>
                    <th>G-800</th>
                    <th>G-900</th>
                    <th>G-1000</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Appearance</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Moisture</td>
                    <td>%max</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Hardness</td>
                    <td>min</td>
                    <td>85</td>
                    <td>85</td>
                    <td>95</td>
                    <td>95</td>
                    <td>95</td>
                </tr>
                <tr>
                    <td>pH</td>
                    <td></td>
                    <td>8-11</td>
                    <td>8-11</td>
                    <td>8-11</td>
                    <td>8-11</td>
                    <td>8-11</td>
                </tr>
                <tr>
                    <td>lodine Adsorption </td>
                    <td>mg/gm min </td>
                    <td>500</td>
                    <td>600</td>
                    <td>800</td>
                    <td>900</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>lodine Adsorption </td>
                    <td>mg/gm min </td>
                    <td>500</td>
                    <td>600</td>
                    <td>800</td>
                    <td>900</td>
                    <td>1000</td>
                </tr>
                <tr>
                    <td>Bulk Density </td>
                    <td>g/lit </td>
                    <td>0.50 +- 0.1</td>
                    <td>0.50 +- 0.1</td>
                    <td>0.50 +- 0.1</td>
                    <td>0.50 +- 0.1</td>
                    <td>0.50 +- 0.1</td>
                </tr>
                <tr>
                    <td>Ash </td>
                    <td>%max </td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                    <td>5</td>
                </tr>
                <tr>
                    <td>Surface Areas </td>
                    <td>m2/gm </td>
                    <td>600</td>
                    <td>700</td>
                    <td>800</td>
                    <td>1000</td>
                    <td>1100</td>
                </tr>
                <tr>
                    <td>CTC Adsorption </td>
                    <td>%min </td>
                    <td>25</td>
                    <td>45</td>
                    <td>45</td>
                    <td>60</td>
                    <td>70</td>
                </tr>

            </tbody>
        </Table>
        </div>
        <p><b>Coconut shell charcoal based Granular Activated Carbon
for water treatment and effluent treatment, gold recovery and gas odor removal etc.
</b></p>
</Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default ActivatedCarbon;