import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Waste_Water extends Component {

    componentDidMount(){
        document.title = "Waste Water Treatment Plant | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Waste Water Treatment Plant' subtitle='' BkImg='/assets/images/Babji/water/Banner-Waste-Water-Treatment-Plant.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Waste Water Treatment Plant  </h1>
    <Row >
        <Col lg="6">
        <p>
        Best in performance, these Waste Water Treatment Plant are
offered in the market in safe form. In addition to this, these
presented products are easy to install and remain sustained up to
many years. This treatment solution is reliable in working and
made under the guidance of professionals on industry.
</p> 

<h4>FEATURES</h4>
<ul>
    <li>Best Working</li>
    <li>Different Specifications
</li>
    <li>Reliable</li>
</ul>

   
                              </Col>
       <Col lg="6" >
       <Image src='/assets/images/Babji/water/Banner-Waste-Water-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
        </Col>

    </Row>

  
</Container>
<hr></hr>
     
        <Footer/>
        </div>
    );
} 
}
export default Waste_Water;