import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class FilterPebbles extends Component {

    componentDidMount(){
        document.title = "Filter Pebbles Gravels Media | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Filter Pebbles Gravels Media' subtitle='' BkImg='/assets/images/Babji/Pebbles-stone.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Filter Pebbles Gravels Media  </h1>
    <Row >
        <Col lg="12">
        <p>
        Our range of Filter Pebbles Gravels are competitive including Filter Pebbles
Gravels, which is widely applicable and uniform in size. It is used for water
filtration, water treatment Gardening, Decoration and many more. The product
is fine in texture. We believe in making superior grade products as we are a
client centered organization with a revered client base across the globe.
It is also known as silica powder
            </p> 
            
<p><b>It is also known as silica powder.</b></p> 

<Image src='/assets/images/Babji/products/Filter-Pebbles.png' alt={config.SITE_NAME} fluid /> 

                              </Col>
       

                           
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
    <Col lg='12'> 
        <div class='table-responsive'>
        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Packaging Type</td>
                    <td>50 kgs</td>
                     
                </tr>
                <tr>
                    <td>Form</td>
                    <td>Natural Sizes all sizes</td>
                     
                </tr>
                <tr>
                    <td>Moisture (%) </td>
                    <td>1% max</td>
                     
                </tr>
                <tr>
                    <td>Purity (%)</td>
                    <td>99.5% Natural</td>
                      
                </tr>
                <tr>
                    <td>Material</td>
                    <td>Pebbles and Gravels</td>
                      
                </tr>
                 

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default FilterPebbles;