import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';
import config from '../../constant';
import { useState } from 'react';

const Footer = () => {

  const [isActive, setIsActive] = useState( );
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
};
const handleClick = event => {
// 👇️ toggle isActive state on click
setIsActive(current => !current);
};

     return(
      <>
        <div className='footerSection'>
          {/* row-cols-4  */}
     <Container className='py-4'> 
     <footer className="row      ">
    <Col lg="4" >
      <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
       </a>
      <p className="text-white"><Image src="assets/images/Babji/Babji-Logo.png"  fluid/></p>

     
    </Col>

    

    <Col lg="4">
      <h5>Quick Links</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2"> <NavLink to="/" >Home</NavLink>
</li>
        <li className="nav-item mb-2">  <NavLink to="/contact" >Contact</NavLink></li>
         
       </ul>
    </Col>

    <Col lg="4">
      <h5>Address</h5>
      <p>Super Mall S-20, Salunke Vihar RD., Wanowri - Pune 411040</p>
<p><i className="fa fa-envelope"></i> <a href={"mailto:" + config.email_id}>{config.email_id}</a>  </p>

<p><i className="fa fa-envelope"></i> <a href={"mailto:" + config.email_id2}>{config.email_id2}</a></p>
<p><i className="fa fa-mobile"></i> <a href= {"tel:" + config.contact1}  >{config.contact1}</a> | <a href= {"tel:" + config.contact2}> {config.contact2}</a> | <a href= {"tel:" + config.contact3}> {config.contact3}</a> | <a href= {"tel:" + config.contact4}> {config.contact4}</a> | <a href= {"tel:" + config.contact5}> {config.contact5}</a></p>
    </Col>

    
  </footer>

  
    </Container>

        </div>

<div class='copyright py-3'>
<Col lg='12' >
<p className='text-center'>Copyright ©️ {getYear()-1}-{getYear()} BABJI FILTRATIONS PRIVATE LIMITED. All Rights reserved.</p>

</Col>
</div>

<a href="https://api.whatsapp.com/send?phone=+919028046401&amp;text=Hello we are interested in activated carbon and water filter media." class="float effetMoveGradient " target="_blank">

<i class="fa fa-whatsapp my-float "></i>

</a>
 
<a href="https://www.instagram.com/babjifiltrations/" class="float_left effetMoveGradient " target="_blank">

<i class="fa fa-instagram my-float "></i>

</a>
</>
    );

}

export default Footer;
