import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Mno2 extends Component {

    componentDidMount(){
        document.title = "MNO2 Iron Removal Filter Media | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='MNO2 Iron Removal Filter Media ' subtitle='' BkImg='/assets/images/Babji/MNO2-iron-removal.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">MNO2 Iron Removal Filter Media (Manganese Dioxide)</h1>
    <Row >
        <Col lg="6">
        <p>
        These Manganese Dioxide Granules are made from high-quality of raw material
which ensures durability at its user end. Different grades of Manganese Dioxide
(MNO2) are manufactured ranging from 60% MN02 to 90% MNO2 in powder as well as
granular form.MnO2 Granules use for water Purification plants, Paint Industries,
Ceramic Industries etc. These Manganese Dioxide Granules are appreciated by a
large number of clients. These products are available in market at economical rates.
Applications


            </p> 
            <Image src='/assets/images/Babji/products/MNO2-Iron.png' alt={config.SITE_NAME} fluid /> 

                              </Col>
       <Col lg="3" >
            <h3 className='secondHeading text-center mb-3'>Applications</h3>

            <ul>
                <li>· Highly Suitable for purification and filtration Processes for
removal of iron and manganese from potable water.
</li>
                
            </ul>
                              </Col>

                             <Col lg="3">
                             <h3 className='secondHeading text-center  mb-3'>Features</h3>
                             <ul>
                <li>High Catalytic Activity</li>
                <li>Inert and non-toxic</li>
            </ul>
                              </Col>
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
        <Col lg='12'> 
        <div class='table-responsive'>
            
        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    <th colSpan={2}>Standard</th>
                   
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>MnO2 Content</td>
                    <td>%min</td>
                    <td>30-45</td>
                    <td>65</td>
                     
                </tr>
                <tr>
                    <td>Appearance</td>
                    <td></td>
                    <td colSpan={2}>Black Granular</td>
                    
                </tr>
                <tr>
                    <td>Particle Size</td>
                    <td>mm</td>
                    <td colSpan={2}>As Required</td>
                    
                </tr>
                <tr>
                    <td>Moisture</td>
                    <td>%max</td>
                    <td colSpan={2}>10</td>
                     
                </tr>
                <tr>
                    <td>Ash Content</td>
                    <td>%max</td>
                    <td colSpan={2}>3</td>
                    
                </tr>
                <tr>
                    <td>Bulk Density</td>
                    <td>kg/m3</td>
                    <td colSpan={2}>1700</td>
                    
                </tr>

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default Mno2;