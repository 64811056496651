import React, { Component }  from 'react';
 import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';

 

       class Header extends Component {
    
    render(){
    return(

      <div>
 <div className='topBar  d-block d-sm-none '>
        <Container fluid>
          <Row>
            <Col lg="12">

                    <Nav  className="justify-content-center "
              // activeKey="/home"
              // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
             
             <span>Certificate : ISO 9001 : 2015 | ISO 14001 : 2015 | MSME | SSI | NSIC  </span>
               
            </Nav>

            </Col>
          </Row>
        </Container>
      </div>      
        <Navbar collapseOnSelect expand="lg" className="justify-content-center" >
      <Container>
        <Navbar.Brand> <NavLink to="/"   activeclassname="active"><Image src="/assets/images/Babji/Babji-Logo.png"  fluid/> </NavLink> </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto justify-content-center py-3 d-none d-sm-block"  >
          <span>Certificate : ISO 9001 : 2015 | ISO 14001 : 2015 | MSME | SSI | NSIC  </span>

            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
           
          </Nav>
          <Nav className='m-auto'>
          <NavLink to="/"   activeclassname="active">Home</NavLink>
          {/* <NavLink to="/about"   activeclassname="active">About</NavLink> */}
               <NavDropdown title="Products" id="collasible-nav-dropdown" className=''>

               <NavDropdown title="Water Treatment Plants Unit" id="basic-nav-dropdown" align="end">
              <NavDropdown.Item href=""> <NavLink to="/sewage-water-treatment-plant"   activeclassname="active">Sewage Water Treatment Plant</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/waste-water-treatment-plant"   activeclassname="active">Waste Water Treatment Plant</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/industrial-waste-water-treatment-plant"   activeclassname="active">Industrial Waste Water Treatment Plant</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/packaged-sewage-treatment-plant"   activeclassname="active">Packaged Sewage Treatment Plant </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/reverse-osmosis-plant"   activeclassname="active">Reverse Osmosis Plant </NavLink></NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Filtration Media " id="basic-nav-dropdown" align="end">

              <NavDropdown.Item href=""> <NavLink to="/activated-carbon-granular"   activeclassname="active">Activated Carbon Granular</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/activated-carbon-powder"   activeclassname="active">Activated Carbon Powder</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/anthracite-filter-media"   activeclassname="active">Anthracite Filter Media</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/pallets-activated-carbon"   activeclassname="active">Pellets Activated Carbon </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/mno2-iron-removal-filter-media"   activeclassname="active">MNO2 Iron Removal Filter Media </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/quartz-silica-sand"   activeclassname="active">Filter Quartz Silica Sand </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/filter-pebbles-gravels-media"   activeclassname="active">Filter Pebbles Gravels Media</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/mbbr-fab-media"   activeclassname="active">MBBR Fab Media </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/garnet-sand"   activeclassname="active">Garnet Sand</NavLink></NavDropdown.Item>
              </NavDropdown>


              


            </NavDropdown>
              <NavLink to="/contact"   activeclassname="active">Contact</NavLink>
            {/* <Nav.Link href="#deets">More deets</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
           );
}

  }
export default Header;
