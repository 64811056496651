import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Garnet_sand extends Component {

    componentDidMount(){
        document.title = "Garnet Sand | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Garnet Sand' subtitle='' BkImg='/assets/images/Babji/Garnet-Sand.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Garnet Sand  </h1>
    <Row >
        <Col lg="6">
        <p>
        Garnet Sand is used as a support layer in a multi-media filtration system. Garnet is
a naturally occurring gem mineral with uniform physical, chemical, hardness,
friability and micro-structure characteristics, which provide the essential properties
for water and industrial chemical filtration processes. Applications for garnet sand
range from small, single-media filters to technically advanced multimedia
commercial and industrial systems. Garnet sand helps to remove suspended
particles from chemical and/or water filtration processes.



            </p> 
            <Image src='/assets/images/Babji/Garnet-Sand.jpg' alt={config.SITE_NAME} fluid /> 
   
                              </Col>
       <Col lg="6" >
            <h3 className='secondHeading text-center mb-3'>Advantages</h3>

            <ul>
            <li>Garnet Sand Physical Characteristics</li>
<li>High specific gravity: 3.8-4.2</li>
<li>Weight per cubic foot: 120 - 151 lbs</li>
<li>MOH hardness scale: 7.6-8.0</li>
<li> Acid solubility: &lt; 1% </li>
<li>Cleanliness: Low dust</li>
<li> Durability: Because of its hardness, high specific gravity
and the pounds per cubic foot, garnet sand, when
installed correctly, stays in its own layer holding down the
layers underneath during backwash.</li>
<li>The layers above the garnet sand are lighter in weight.
(per cubic foot) and therefore they lift during backwash
and dislodge the "floc" cleaning of the filter media. The</li>
<li>• Garnet sand is not used to replace silica sand but rather
one or two support layers of garnet sand are used
beneath the silica sand. Garnet sand is so durable and
heavy that the layers above will be replenished or
replaced well before the garnet and filter rock layers are
replaced. Therefore, time and money are saved by using
garnet sand in a filtering system.</li>

                 
            </ul>
                              </Col>

    </Row>

  
</Container>
<hr></hr>
     
        <Footer/>
        </div>
    );
} 
}
export default Garnet_sand;