import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Reverse_osmosis extends Component {

    componentDidMount(){
        document.title = "Reverse Osmosis Plant | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Reverse Osmosis Plant' subtitle='' BkImg='/assets/images/Babji/water/Banner-REVERSE-OSMOSIS-PLANT.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Reverse Osmosis Plant  </h1>
    <Row >
        <Col lg="6">
        <p>
        Keeping the track of latest happening in this domain, We manufacture and
supply the premium quality of Reverse Osmosis Plant. Known for its high
performance and low maintenance, the offered reverse osmosis plant is used
for water purification applications. For utmost quality, we follow total quality
management while manufacturing the offered reverse osmosis plant. 




            </p>
            

    <h3 className='secondHeading text-center mb-3'>FEATURES</h3>

<ul>

<li>Reverse osmosis can stably and effectively remove dissolved salts, dissolved organic substances (Trihalomethane,
its precursors, agricultural chemicals, etc) and micro fine particles (Living germs, dead germs, and many other
micro fine particles) from water. Thus it is ideal for a wide area of application of ultra pure water to desalination of
seawater.</li>
<li>Reverse osmosis does not need heating, it can concentrate and recover valuable process materials dissolved in a
solution without any degradation which might otherwise occur in materials.</li>
<li>Reverse osmosis keeps water from evaporating, making it energy-saving separation technique that requires less
energy consumption.</li>

</ul>
   
                              </Col>
       <Col lg="6" >
           


            <Image src='/assets/images/Babji/water/Banner-REVERSE-OSMOSIS-PLANT.jpg' alt={config.SITE_NAME} fluid /> 

                              </Col>

    </Row>

  
</Container>
<hr></hr>
     
        <Footer/>
        </div>
    );
} 
}
export default Reverse_osmosis;