const config = { 
    BASE_URL : "https://server.babjifiltrations.com/",
    // BASE_URL : "http://localhost:3001/",
SITE_NAME : 'Babji Filterations',
contact1:'8975785582',
contact2:'9028046401',
contact3:'8446744111',
contact4:'8446255777',
contact5:'8983352533',
email_id:'sales@babjifiltrations.com',
email_id2:'info@babjifiltrations.com'

 
     
}
export default config;