import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Quartz extends Component {

    componentDidMount(){
        document.title = "Quartz Silica Sand | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Quartz Silica Sand ' subtitle='' BkImg='/assets/images/Babji/Filter-quartz-silica-sand.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Quartz Silica Sand  </h1>
    <Row >
        <Col lg="12">
        <p>
        Our firm specializes in MFG supplying, exporting & wholesaling deft free Quartz
Silica Sand. The chemical nomenclature of our offered sand is SiO2 which is better
known as silicon dioxide. This Silica sand is precisely processed by crushing sand
stone or quartz of open texture, and washing and grading it to yield requisite grain
distribution. We offer this sand in various quantities and packaging options at
reasonable prices.



            </p> 
            <p>Our range of Quartz sand is competitive including Silica Sand, which is widely
applicable and uniform in size. It is used for water filtration, foundry, painting, water
treatment and plastering. We believe in making superior-grade products as we
are a client-centered organization with a revered client base across the globe.
 </p>
<p><b>It is also known as silica powder.</b></p> 

<Image src='/assets/images/Babji/products/Filter-Qurtz.png' alt={config.SITE_NAME} fluid /> 

                              </Col>
       

                           
    </Row>

  
</Container>

<div className='grayBg '>
    <Container>
    <Row className='py-5'>
    <Col lg='12'> 
        <div class='table-responsive'>
        <Table     bordered hover>
            <thead>
                <tr>
                    <th>Properties</th>
                    <th></th>
                    
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Packaging Type</td>
                    <td>50 kgs</td>
                     
                </tr>
                <tr>
                    <td>Form</td>
                    <td>Crystal Granules</td>
                     
                </tr>
                <tr>
                    <td>Moisture (%) </td>
                    <td>1% max</td>
                     
                </tr>
                <tr>
                    <td>Purity (%)</td>
                    <td>99.5% silica content</td>
                      
                </tr>
                <tr>
                    <td>Material</td>
                    <td>Silica</td>
                      
                </tr>
               

            </tbody>
        </Table>
        </div>
        </Col>
    </Row>
    </Container>
</div>      
        <Footer/>
        </div>
    );
} 
}
export default Quartz;