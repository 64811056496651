import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';

 class Packaged_sewage extends Component {

    componentDidMount(){
        document.title = "Packaged Sewage Water Treatment Plant | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Sewage Water Treatment Plant' subtitle='' BkImg='/assets/images/Babji/water/Banner-Packaged-Sewage-Treatment-Plant.jpg' /> 

<Container className=" py-5 ProductsSection ">
    <h1 className=" pb-3  mt-0">Packaged Sewage Water Treatment Plant  </h1>
    <Row >
        <Col lg="6">
        <p>
        As a reliable manufacturer and supplier, we offer our customers
with superior quality Packaged Sewage Treatment Plant. Available
with us in various specifications, the offered packaged sewage
treatment plant is known for its excellent sewage water treatment
capacity. Manufactured as per the industry laid norms, the offered
plant is used for sewage treatment process.
</p> 

<p>

Assisted by a crew or skilled and assiduous personnel, we are
offering a comprehensive array of Sewage Treatment Plant. These
plants are designed and made up by making utilization of top
material which is been purchased from specialized sellers of the
industry. To ensure its excellence, this product is tested against
different quality limitations and delivered on time in top class
packaging.



            </p> 
   
                              </Col>
       <Col lg="6" >
       <Image src='/assets/images/Babji/water/Banner-Packaged-Sewage-Treatment-Plant.jpg' alt={config.SITE_NAME} fluid /> 
        </Col>

    </Row>

  
</Container>
<hr></hr>
     
        <Footer/>
        </div>
    );
} 
}
export default Packaged_sewage;